.titulo-h2 {
  font-family: "tahoma";
  font-weight: bold;
  color: rgb(6, 5, 80);
}

.titulo-h3 {
  font-family: "tahoma";
  font-weight: bold;
  color: rgb(6, 5, 80);
}

.botao .ant-btn-primary {
  background-color: rgb(6, 5, 80);
  border-color: rgb(6, 5, 80);
}

.eixo-tela-login {
  margin-top: 20px;
}

div {
  padding: 2px;
}

div .ant-col .texto-nome-input {
  display: table-cell;
  vertical-align: middle;
}

.my-drawer {
  width: 50%;
}
@media only screen and (max-width: 797) {
  .my-drawer {
    width: 90%;
  }
}
